import './ImageViewer.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

function ImageViewer({currentImage, setCurrentImage, imageSrc, setImageSrc, imagesInSession, setImagesInSession, configData }) {

    const[imageDisplayed, setImageDisplayed] = useState(currentImage);

    useEffect(() => {
        if(currentImage!==null && currentImage!==imageDisplayed) {
            setImageSrc('/img/spinner.gif');
            axios
                .get(
                    configData.SERVER_API_BASE + "image/" + currentImage,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + localStorage.token
                        }
                    })
                .then((res) => {
                    let alreadyThere = false;
                    setImageDisplayed(currentImage);
                    setImageSrc('data:image/' + (res?.data?.extension==='JPG' ? 'jpeg' : 'png') + ';base64,' + res?.data?.image);
                    setImageDisplayed(res?.data?.filename);
                    let tmp = [...imagesInSession];
                    tmp.forEach(element => {
                        element.checked = false;
                        if(element.name === currentImage) {
                            alreadyThere = true;
                        }
                    })
                    if(alreadyThere!==true)
                        tmp.push({ name : currentImage, checked : false, source: 'data:image/' + (res?.data?.extension==='JPG' ? 'jpeg' : 'png') + ';base64,' + res?.data?.image });
                    setImagesInSession(tmp);
                });
        }
    }, [currentImage])

    return (
        <div className='image_viewer'>
            <div className="image_wrapper">
                <img src={imageSrc} />
            </div>
        </div>
    )

}

export default ImageViewer;