import './OptionMenu.css';
import { useRef, useState, useEffect } from 'react';
import { Stack, Button, Snackbar, Alert, Drawer, Paper, MenuList, MenuItem, ListItemText, Select, InputLabel, TextField, ListItemIcon, Divider } from '@mui/material';
import axios from 'axios';

function OptionMenu({
    currentImage, setCurrentImage, originalImage, setOriginalImage, setImageSrc, imagesInSession, setImagesInSession,
    selectedPalette, setSelectedPalette, selectedPixelization, setSelectedPixelization, selectedEffect, setSelectedEffect, 
    selectedEffectObject, setSelectedEffectObject, selectedEffectOptions, setSelectedEffectOptions, selectedMerge, setSelectedMerge, resetFilters,
    configData
}) {

    const hiddenFileInput = useRef(null);
    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [paletteList, setPaletteList] = useState([]);
    const [pixelizationList, setPixelizationList] = useState(configData.PIXELIZATION_LIST);
    const [effectList, setEffectList] = useState(configData.EFFECT_LIST);
    const [mergeList, setMergeList] = useState(configData.MERGE_LIST);
    const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const uploadImage = event => {
        hiddenFileInput.current.click();
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }

    const handleFileChange = async(event) => {
        const fileUploaded = event?.target?.files[0];
        if(fileUploaded) {
            setImageSrc('/img/spinner.gif');
            let data = {};
            data.name = fileUploaded.name;
            data.base64 = await convertBase64(fileUploaded);
            axios.post(configData.SERVER_API_BASE + "images", 
                JSON.stringify(data), 
                {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.token,
                }
            })
            .then((res) => {
                setCurrentImage(res?.data?.data?.name);
                setOriginalImage(res?.data?.data?.name);
                resetFilters();
            })
            .catch((res) => {
                setErrorMessage(res.message + ' [' + res.response.data.message + ']')
                setOpen(true);
            });

        }
    };

    useEffect(() => {
        if(paletteList.length===0) {
            axios.get(configData.SERVER_API_BASE + "palettes", 
                {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.token,
                }
            })
            .then((res) => {
                setPaletteList(res?.data);
            })
            .catch((res) => {
                setErrorMessage(res.message + ' [' + res.response.data.message + ']')
                setOpen(true);
            });
        }
    }, [currentImage]);

    const handleClose = () => {
        setOpen(false);
    }

    const handleFilterChange = (event) => {
        let pal = '';
        let pix = '';
        let eff = '';
        if(event) {
            if(event.target.name==='pixelization') {
                setSelectedPixelization(event.target.value);
                pix = event.target.value;
                pal = selectedPalette;
                eff = selectedEffect;
            } else if(event.target.name==='palette') {
                setSelectedPalette(event.target.value);
                pal = event.target.value;
                pix = selectedPixelization;
                eff = selectedEffect;
            } else if(event.target.name==='effect') {
                setSelectedEffect(event.target.value);
                let effectIndex = effectList.findIndex((effect) => effect.id===event.target.value);
                if(effectIndex>=0)
                    setSelectedEffectObject(effectList[effectIndex]);
                else
                    setSelectedEffectObject({});
                eff = event.target.value;
                pal = selectedPalette;
                pix = selectedPixelization;
            }
        } else {
            pal = selectedPalette;
            pix = selectedPixelization;
            eff = selectedEffect;
        }
        setImageSrc('/img/spinner.gif');
        let data = {};
        data.pal = pal;
        data.pix = pix;
        data.eff = eff;
        data.options = selectedEffectOptions;
        axios.post(configData.SERVER_API_BASE + "image/" + originalImage, 
            JSON.stringify(data), 
            {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token,
            }
        })
        .then((res) => {
            setCurrentImage(res?.data?.data?.name);
        })
        .catch((res) => {
            setErrorMessage(res.message + ' [' + res.response.data.message + ']')
            setOpen(true);
        });
    }

    const deleteSelectedImages = async () => {
        await imagesInSession.forEach((item) => {
            if(item.checked) {
                axios.delete(configData.SERVER_API_BASE + "images/" + item.name, 
                    {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.token,
                    }
                })
                .then((res) => {
                    if(currentImage===item.name) {
                        setCurrentImage(null);
                        setImageSrc(null);
                    }
                    if(originalImage===item.name) {
                        setOriginalImage(null);
                    }
                })
                .catch((res) => {
                    setErrorMessage(res.message + ' [' + res.response.data.message + ']')
                    setOpen(true);
                });
            }
        });
        let tmp = imagesInSession.filter((item) => item.checked === false);
        setImagesInSession(tmp);
        resetFilters();
    }

    const handleGroupOperation = (event) => {
        let data = {};
        setSelectedMerge(event.target.value);
        data["operation"] = event.target.value;
        data["images"] = [];
        imagesInSession.forEach((item) => {
            if(item.checked) {
                data["images"][item.position - 1] = item.name
            }
        })
        axios.post(configData.SERVER_API_BASE + "image/group", 
            JSON.stringify(data), 
            {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token,
            }
        })
        .then((res) => {
            setCurrentImage(res?.data?.data?.name);
            let tmp = [...imagesInSession];
            tmp.forEach((item) => {
                item.checked = false;
                item.position = 0;
            })
            setImagesInSession(tmp);
            resetFilters();
        })
        .catch((res) => {
            setErrorMessage(res.message + ' [' + res.response.data.message + ']')
            setOpen(true);
        });
    }

    const handleEffectOptionChange = (event) => {
        let tmp = selectedEffectOptions;
        tmp[selectedEffectObject.id][event.target.name] = event.target.value;
        setSelectedEffectOptions(tmp);
        handleFilterChange(null);
    }

    return(
        <>
            <div className="menu">
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    ref={hiddenFileInput}
                    style={{display: 'none'}}
                    />
                <Stack spacing={2}>
                    <Button variant="contained" color="primary" onClick={uploadImage}>Upload image</Button>
                    {/*<Button variant="contained" color="primary" onClick={toggleDrawer('left', true)}>Album</Button>*/}
                    {
                        imagesInSession.filter((t) => t.checked === true).length>0 ?
                            (
                                <Button variant="contained" color="primary" onClick={deleteSelectedImages}>Delete {imagesInSession.filter((t) => t.checked === true).length} image{imagesInSession.filter((t) => t.checked === true).length > 1 ? 's' : ''}</Button>
                            )
                            :
                            null
                    }
                    {
                        currentImage ?
                        <>

                            <TextField
                                select
                                value={selectedPalette}
                                label="Palette"
                                onChange={handleFilterChange}
                                name='palette'
                            >
                                <MenuItem key={666} value=''>None</MenuItem> 
                                {
                                    paletteList.map((paletteItem, index) => {
                                        return(
                                            <MenuItem key={index} value={paletteItem.id}>{paletteItem.name}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>

                            <TextField
                                select
                                value={selectedPixelization}
                                label="Pixelization"
                                onChange={handleFilterChange}
                                name='pixelization'
                            >
                                <MenuItem key={666} value=''>None</MenuItem>
                                {
                                    pixelizationList.map((pixelization, index) => {
                                        return(
                                            <MenuItem key={index} value={pixelization.value}>{pixelization.name}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>

                            <TextField
                                select
                                value={selectedEffect}
                                label="Effect"
                                onChange={handleFilterChange}
                                name='effect'
                            >
                                <MenuItem key={666} value=''>None</MenuItem>
                                {
                                    effectList.map((effect, index) => {
                                        return(
                                            <MenuItem key={index} value={effect.id}>{effect.name}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>                        
                            {
                                selectedEffectObject.options &&
                                        selectedEffectObject.options.map((option, index) => {
                                            return(
                                                <TextField
                                                    select
                                                    value={selectedEffectOptions[selectedEffectObject.id][option.id]}
                                                    label={option.name}
                                                    onChange={handleEffectOptionChange}
                                                    name={option.id}
                                                >
                                                    {
                                                        option.values.map((v, index) => {
                                                            return(
                                                                <MenuItem key={index} value={v.id}>{v.name}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </TextField> 
                                            )
                                        })
                            }
                        </>
                        :
                        null
                    }
                    {
                        imagesInSession.filter((t) => t.checked === true).length>1 ?
                        (
                            <TextField
                                select
                                value={selectedMerge}
                                label="Merge"
                                onChange={handleGroupOperation}
                                name='merge'
                            >
                                <MenuItem value=''>None</MenuItem>
                                {
                                    mergeList.map((merge, index) => {
                                        return (
                                            !merge.conditions || merge.conditions.includes(imagesInSession.filter((t) => t.checked === true).length) ?
                                                (
                                                    <MenuItem key={index} value={merge.value}>{merge.name}</MenuItem>
                                                )
                                                :
                                                null
                                        )
                                    })
                                }
                            </TextField> 
                        )
                        :
                        null
                    }
                </Stack>

                {/* <Drawer
                    anchor='left'
                    open={state['left']}
                    onClose={toggleDrawer('left', false)}
                    // PaperProps={{ sx: { width: "90%" } }}
                    >
                    <Album setCurrentImage={setCurrentImage} setOriginalImage={setOriginalImage} resetFilters={resetFilters} />
                </Drawer> */}
            </div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert severity="error" sx={{ width: '100%' }} onClose={handleClose}>
                    { errorMessage }
                </Alert>
            </Snackbar>
        </>
    )
}

export default OptionMenu;