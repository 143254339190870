import { useState } from 'react';
import OptionMenu from '../../components/OptionMenu/OptionMenu';
import ImageViewer from '../../components/ImageViewer/ImageViewer';
import WorkInProgress from '../../components/WorkInProgress/WorkInProgress';
import './MainBlock.css';

function MainBlock({ currentImage, setCurrentImage, originalImage, setOriginalImage, wipVisible, configData }) {

    const [imageSrc, setImageSrc] = useState(null);
    const [imagesInSession, setImagesInSession] = useState([]);
    const [selectedPalette, setSelectedPalette] = useState('');
    const [selectedPixelization, setSelectedPixelization] = useState('');
    const [selectedEffect, setSelectedEffect] = useState('');
    const [selectedEffectObject, setSelectedEffectObject] = useState({});
    const [selectedEffectOptions, setSelectedEffectOptions] = useState(configData.DEFAULT_EFFECT_OPTIONS);
    const [selectedMerge, setSelectedMerge] = useState('');

    
    const resetFilters = () => {
        setSelectedPalette('');
        setSelectedPixelization('');
        setSelectedEffect('');
        setSelectedEffectObject({});
        setSelectedMerge('');
        setSelectedEffectOptions(configData.DEFAULT_EFFECT_OPTIONS);
    }

    return (
        <div className="main_block">

            <WorkInProgress 
                imagesInSession={imagesInSession} setImagesInSession={setImagesInSession} 
                setCurrentImage={setCurrentImage} 
                originalImage={originalImage} setOriginalImage={setOriginalImage} 
                resetFilters={resetFilters}
                wipVisible={wipVisible}
                configData={configData}
            />

            <ImageViewer 
                currentImage={currentImage} setCurrentImage={setCurrentImage} 
                imageSrc={imageSrc} setImageSrc={setImageSrc} 
                imagesInSession={imagesInSession} setImagesInSession={setImagesInSession} 
                configData={configData}
            />

            <OptionMenu 
                currentImage={currentImage} setCurrentImage={setCurrentImage} 
                originalImage={originalImage} setOriginalImage={setOriginalImage} 
                setImageSrc={setImageSrc} 
                imagesInSession={imagesInSession} setImagesInSession={setImagesInSession} 
                selectedPalette={selectedPalette} setSelectedPalette={setSelectedPalette}
                selectedPixelization={selectedPixelization} setSelectedPixelization={setSelectedPixelization}
                selectedEffect={selectedEffect} setSelectedEffect={setSelectedEffect}
                selectedEffectObject={selectedEffectObject} setSelectedEffectObject={setSelectedEffectObject}
                selectedEffectOptions={selectedEffectOptions} setSelectedEffectOptions={setSelectedEffectOptions}
                selectedMerge={selectedMerge} setSelectedMerge={setSelectedMerge}
                resetFilters={resetFilters}
                configData={configData}
            />

        </div>
    )
}

export default MainBlock;