import { React, useState } from 'react';
import { TextField, Button } from '@mui/material';
import './LoginForm.css';
import axios from 'axios';

function LoginForm({setConnected, configData}) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        if (email && password) {
            let conn = axios.create({

                baseURL: configData.SERVER_WS_BASE,
                withCredentials: true,
                headers: {
                    common: {
                        "Accept": "application/json",
                    }
                }
            });
            conn.get('sanctum/csrf-cookie', ).then(() => {
                conn.post('api/login', {
                    email,
                    password,
                })
                .then((res) => {
                    if(res.data.success===true) {
                        setConnected(true);
                        localStorage.setItem("token", res.data.data.token);
                    }
                })
                .catch((res) => {
                    console.log(res)
                });    
            })
        } else {
            alert('Both fields are required!');
        }
    };

    return (

        <div className="login-form">
            <TextField label="Email" value={email} onChange={e => setEmail(e.target.value)} variant="outlined" fullWidth />
            <TextField label="Password" value={password} onChange={e => setPassword(e.target.value)} type="password" variant="outlined" fullWidth />
            <Button variant="contained" color="primary" onClick={handleLogin}>Login</Button>
        </div>

    );

}

export default LoginForm;