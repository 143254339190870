import './WorkInProgress.css';
import { useEffect, useState, useRef, useCallback } from 'react';
import { Checkbox } from '@mui/material';
import axios from 'axios';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CircularProgress from '@mui/material/CircularProgress';



function WorkInProgress({ imagesInSession, setImagesInSession, setCurrentImage, originalImage, setOriginalImage, resetFilters, wipVisible, configData }) {

    const[loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(configData.SERVER_API_BASE + "images", 
            {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then(async (res) => {
            let temp = res?.data;
            let total = temp.length;
            if(total===0) setLoading(false);
            await temp.forEach(async (image, index) => {
                image.checked = false;
                image.position = 0;
                image.key = index;
                await axios.get(configData.SERVER_API_BASE + "image/" + image.name, 
                    {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.token
                    }
                }).then((res2) => {
                    image.source = 'data:image/' + (res2?.data?.extension==='JPG' ? 'jpeg' : 'png') + ';base64,' + res2?.data?.image;
                    total--;
                    setLoading(total!==0);
                }).catch((res2) => {
                    console.log(res2);
                })
            })
            setImagesInSession([...temp]);
        })
        .catch((res) => {
            console.log(res);
        });
    }, [])

    const handleCheckboxChange = (event) => {
        let index = -1;
        let selected = 0;
        let unselectedPosition = 0;
        imagesInSession.forEach((image, image_idx) => {
            if(image.name === event.target.value) {
                index = image_idx;
                image.checked = event.target.checked;
            }
            if(image.checked) {
                selected++;
            }
        });
        if(event.target.checked)
            imagesInSession[index].position = selected;
        else {
            unselectedPosition = imagesInSession[index].position;
            imagesInSession[index].position = 0;
            imagesInSession.forEach((image) => {
                if(image.position>unselectedPosition)
                    image.position = image.position - 1;
            });
        }
        setImagesInSession([...imagesInSession]);
    }

    const handleWIPImageClick = (event) => {
        setCurrentImage(event.target.name);
        setOriginalImage(event.target.name);
        resetFilters();
    }

    return (
        <div className='work_in_progress' style={ !wipVisible ? { display : 'none' } : { display: 'flex'} }>
            {
                loading && 
                <div className="loading">
                    <CircularProgress />
                </div>
            }

            { !loading && 
                imagesInSession.slice(0).reverse().map((item, index) => {
                    return(
                        <div className="wip_img_wrapper" key={index}>
                            <img className={ item.checked ? "selected" : ""} key={item.key} src={item.source} onClick={handleWIPImageClick} name={item.name} />
                            <div className="wip_checkbox">
                                <Checkbox
                                    value={item.name}
                                    checked={item.checked}
                                    onChange={handleCheckboxChange}
                                 />
                            </div>
                            <div className='image_original' style={ item.name !== originalImage ? { display : 'none'} : null }><RemoveRedEyeIcon /></div>
                            <div className="image_position" style={ !item.checked ? { display : 'none'} : null }>{item.position && item.position>0 ? item.position : null}</div>
                        </div>
                    )
                })
            }
        </div>
    )

}

export default WorkInProgress;