import './Header.css';

function Header() {

    return (
        <>
            <div className="logo">
                <a href="/">
                    <img src="/img/logo.png" />
                </a>
            </div>
        </>
    )
}

export default Header;