import './HeaderLogged.css';
import { React, useState, useEffect } from 'react';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SaveIcon from '@mui/icons-material/Save';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import LogoutIcon from '@mui/icons-material/Logout';

function HeaderLogged({currentImage, setConnected, wipVisible, setWipVisible, configData}) {

    const[userName, setUserName] = useState(null);
    const[userSpace, setUserSpace] = useState('');

    function humanFileSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
          return bytes + ' B';
        }
        const units = si 
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;
        do {
          bytes /= thresh;
          ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
        return bytes.toFixed(dp) + ' ' + units[u];
    }

    useEffect(() => {
        axios.get(configData.SERVER_API_BASE + "user/info", 
        {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then((res) => {
            setUserName(res?.data?.name);
            setUserSpace(humanFileSize(res?.data?.used_space, false, 2));
        })
        .catch((res) => {
            console.log(res.message + ' [' + res.response.data.message + ']')
        });

    }, [currentImage])

    const logout = () => {
        setConnected(false);
        localStorage.clear();
    }

    const toggleWIP = () => {
        setWipVisible(!wipVisible);
    }

    return (
        <>
            <div className="header_wrapper">
                <div className="header_logged_logo">
                    <a href="/">
                        <img src="/img/logo.png" />
                    </a>
                </div>
                <div className="status_bar">
                    <div className='status_section'><AccountCircleIcon /> {userName}</div>
                    <div className='status_section'><SaveIcon /> {userSpace}</div>
                    <div className='status_section button_like mobile' onClick={toggleWIP}><PhotoLibraryIcon /> Album</div>
                    <div className='status_section button_like' onClick={logout}><LogoutIcon /> Logout</div>
                </div>
            </div>
        </>
    )
}

export default HeaderLogged;