import './Footer.css';

function Footer() {

    return (
        <>
            <div className="footer">
                Powered by <a href="https://kresala.fr" target="_blank"><img src="https://static.kresala.fr/img/logo/horneg.png" /></a>
            </div>
        </>
    )
}

export default Footer;