import './App.css';
import { useState, useEffect } from 'react';
import Header from './containers/Header/Header';
import Footer from './containers/Footer/Footer';
import HeaderLogged from './containers/HeaderLogged/HeaderLogged';
import LoginForm from './containers/LoginForm/LoginForm';
import MainBlock from './containers/MainBlock/MainBlock';
import CircularProgress from '@mui/material/CircularProgress';


function App() {
  
  const[connected, setConnected] = useState(false);
  const[currentImage, setCurrentImage] = useState(null);
  const[originalImage, setOriginalImage] = useState(null);
  const[wipVisible, setWipVisible] = useState(true);
  const[configData, setConfigData] = useState(null);

  useEffect(() => {
    fetch('/config/config.json')
      .then((r) => r.json())
      .then((data)=>{
        setConfigData(data);
    });
    if(connected===false && localStorage.token) {
      setConnected(true)
    }
  }, []);

  return (
    <>
      {
        configData ? 
        (
          <>
          {
            connected ?
            (
              <>
                <HeaderLogged 
                  currentImage={currentImage} 
                  setConnected={setConnected} 
                  wipVisible={wipVisible} setWipVisible={setWipVisible} 
                  configData={configData} 
                  />
                <MainBlock 
                  originalImage={originalImage} setOriginalImage={setOriginalImage} 
                  currentImage={currentImage} setCurrentImage={setCurrentImage} 
                  wipVisible={wipVisible}
                  configData={configData} 
                  />
              </>
            )
            :
            (
              <>
                <Header />
                <LoginForm setConnected = {setConnected} configData={configData} />
              </>
            )
          }
          <Footer />
          </>
        )
        :
        <CircularProgress />
      }
    </>
  );
  
}

export default App;
